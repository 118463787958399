<template>
  <section class="splitted">
    <div class="caption-bubble">
      <h3 class="fw-bold m-0">
        {{ $lang("splitted_center.title1", true) }}
        <span class="text-blue2">{{ $lang("splitted_center.title2", true) }}</span>
      </h3>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 p-0"><slot name="start" /></div>
        <div class="col-md-6 p-0"><slot name="end" /></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
