<template>
  <div class="card card-image">
    <ImageContainer :fileName="fileName" class="card-header" background>
      <div class="card-tag" :class="tagClass" v-if="tag">
        {{ tag }}
      </div>
    </ImageContainer>
    <div class="heart-bubble" v-if="withHeart">
      <img src="/imgs/svg/heart_bubble.svg" alt="" />
    </div>
    <div class="card-body" :class="bodyClass">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fileName: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: null,
    },
    tagClass: {
      type: String,
      default: "bg-blue1",
    },
    bodyClass: {
      type: String,
      default: "text-center",
    },
    withHeart: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
