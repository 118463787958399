<template>
  <!-- header -->
  <section class="home_header">
    <div class="video-container">
      <video autoplay muted loop id="myVideo">
        <source :src="videoSrc" type="video/mp4" />
      </video>
      <div class="caption">
        <h1 class="text-green2">{{ $lang("header.title1") }}</h1>
        <h1 class="mb-3">{{ $lang("header.title2") }}</h1>
        <h2 class="fw-regular">{{ $lang("header.subtitle") }}</h2>
      </div>
      <a href="#section1" class="arrows">
        <img src="/imgs/svg/icon_arrows_down.svg" alt="" />
      </a>
    </div>
  </section>
  <!-- section1 -->
  <section class="bg-blue-grad-1 text-white py-8" id="section1">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-5 mb-lg-0">
          <h2 class="fw-bold text-blue2">{{ $lang("section1.title1") }}</h2>
          <h2 class="fw-bold text-green-grad mb-3">
            {{ $lang("section1.title2") }}
          </h2>

          <p class="mb-5">{{ $lang("section1.title3") }}</p>

          <button class="btn btn-green-grad px-5" @click="goTo('contact_us')">
            {{ $lang("section1.button") }}
          </button>
        </div>
        <div class="col-lg-6">
          <VideoContainer
            thumbnailFileName="video-thumb-1"
            videoToken="yUpmnGPsCn0"
            rounded
            class="h-100 mx-4 mx-lg-0"
          >
            <ImageContainer fileName="logo_ned_circle" class="logo_decoration" />
            <img src="/imgs/svg/waves.svg" class="wave_decoration" />
          </VideoContainer>
        </div>
      </div>
    </div>
  </section>
  <MapSection />
  <!-- splitted_section -->
  <SplittedSection>
    <template #start>
      <ImageContainer fileName="campus_dublin" background size="100%" />
      <div class="overlay-green3 px-4 px-md-7">
        <div class="caption">
          <h4 class="text-blue2">{{ $lang("splitted_section.title1") }}</h4>
          <p>{{ $lang("splitted_section.text1") }}</p>
          <button class="btn btn-blue-grad-1" @click="goTo('campus_dublin')">
            {{ $lang("splitted_section.button1") }}
          </button>
        </div>
      </div>
    </template>
    <template #end>
      <ImageContainer fileName="campus_limerick" background size="100%" />
      <div class="overlay-green1 px-4 px-md-7">
        <div class="caption">
          <h4 class="text-blue2">{{ $lang("splitted_section.title2") }}</h4>
          <p>{{ $lang("splitted_section.text2") }}</p>
          <button class="btn btn-blue-grad-1" @click="goTo('campus_limerick')">
            {{ $lang("splitted_section.button2") }}
          </button>
        </div>
      </div>
    </template>
  </SplittedSection>
  <!-- section_courses -->
  <section class="py-7 bg-blue-grad-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-12 mb-6 mb-lg-0">
          <div class="pr-4">
            <h2 class="fw-bold text-green-grad mb-4">
              {{ $lang("section_courses.title") }}
            </h2>
            <h3 class="mb-5 fw-regular">{{ $lang("section_courses.text") }}</h3>
            <button class="btn btn-green-grad btn-block" @click="goTo('courses')">
              {{ $lang("section_courses.button") }}
            </button>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mb-4 mb-lg-0">
          <CardImage class="card-blue2 m-0" withHeart fileName="card_header_1" tag="25-weeks">
            <h5 class="fw-semibold">
              {{ $lang("section_courses.first_card_title") }}
            </h5>
            <p class="opacity-6">
              {{ $lang("section_courses.first_card_text") }}
            </p>
          </CardImage>
        </div>
        <div class="col-lg-3 col-md-4 mb-4 mb-lg-0">
          <CardImage
            class="card-blue3 m-0 text-white"
            fileName="card_header_2"
            tag="4, 8 to 12-weeks"
          >
            <h5 class="fw-semibold">
              {{ $lang("section_courses.second_card_title") }}
            </h5>
            <p class="opacity-6">
              {{ $lang("section_courses.second_card_text") }}
            </p>
          </CardImage>
        </div>
        <div class="col-lg-3 col-md-4 mb-4 mb-lg-0">
          <CardImage class="card-white m-0 text-blue1" fileName="card_header_3" tag="25-weeks">
            <h5 class="fw-semibold">
              {{ $lang("section_courses.third_card_title") }}
            </h5>
            <p class="opacity-6">
              {{ $lang("section_courses.third_card_text") }}
            </p>
          </CardImage>
        </div>
      </div>
    </div>
  </section>
  <!-- studentlife -->
  <section class="pt-7 pb-10 bg-green-grad student-life-section">
    <div class="container">
      <div class="row text-center">
        <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
          <h2 class="fw-bold text-blue2 mb-3">
            {{ $lang("studentlife.title") }}
          </h2>
          <p class="fw-semibold">{{ $lang("studentlife.text") }}</p>
        </div>
      </div>

      <div
        class="d-grid grid-1-cols grid-sm-2-cols grid-lg-4-cols my-5 px-5 px-md-0 gap-4 gap-lg-6"
      >
        <div>
          <div class="icon-container">
            <ImageContainer fileName="stud_life_1" class="img-container-circle mb-4" />
            <ImageContainer class="icon" fileName="icon_pine" />
          </div>

          <p class="text-center">
            <b>{{ $lang("studentlife.activities1") }}</b> {{ $lang("studentlife.activities2") }}
            <b>{{ $lang("studentlife.activities3") }}</b>
          </p>
        </div>
        <div>
          <div class="icon-container">
            <ImageContainer fileName="stud_life_2" class="img-container-circle mb-4" />
            <ImageContainer class="icon" fileName="icon_mobile" />
          </div>

          <p class="text-center">
            <b>{{ $lang("studentlife.app") }}</b> {{ $lang("studentlife.app2") }}
          </p>
        </div>
        <div>
          <div class="icon-container">
            <ImageContainer fileName="stud_life_3" class="img-container-circle mb-4" />
            <ImageContainer class="icon" fileName="icon_home" />
          </div>
          <p class="text-center">
            <b>{{ $lang("studentlife.accomodation1") }}</b> {{ $lang("studentlife.accomodation2") }}
          </p>
        </div>
        <div>
          <div class="icon-container">
            <ImageContainer fileName="stud_life_4" class="img-container-circle mb-4" />
            <ImageContainer class="icon" fileName="icon_plane" />
          </div>

          <p class="text-center">
            <b>{{ $lang("studentlife.airport1") }}</b> {{ $lang("studentlife.airport2") }}
          </p>
        </div>
      </div>

      <div class="row justify-content-center text-center">
        <div class="col-md-6 col-lg-4">
          <router-link class="btn btn-blue-grad-1 btn-block" :to="{ name: 'services' }">
            {{ $lang("studentlife.button") }}
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <CertificationsSection floatting />

  <TestimonialSection class="py-10" />

  <Teleport to="#modal-container">
    <Modal
      v-if="showModal"
      @close="showModal = false"
      :headImage="$lang('modal.image')"
      :headImageMobile="$lang('modal.imageMobile')"
      class="header-pointer no-body"
      @headerClick="modalAction"
    >
    </Modal>
  </Teleport>
</template>

<script>
import Modal from "@/components/Modal";
import MapSection from "@/components/MapSection";
import CertificationsSection from "@/components/CertificationsSection";
import SplittedSection from "@/components/SplittedSection";
import TestimonialSection from "@/components/TestimonialSection";
import CardImage from "@/components/CardImage";

export default {
  components: {
    Modal,
    MapSection,
    SplittedSection,
    TestimonialSection,
    CardImage,
    CertificationsSection,
  },
  data: () => ({
    videoSrc: "/mp4/nedcollege_home_low.mp4",
    showModal: false,
  }),
  mounted() {
    if (window.innerWidth >= 768) {
      this.videoSrc = "/mp4/nedcollege_home_full.mp4";
      const myVideo = document.getElementById("myVideo");
      myVideo.addEventListener("loadedmetadata", () => {
        myVideo.play();
        myVideo.removeEventListener("loadedmetadata", arguments.callee);
      });
    }

    setTimeout(() => {
      this.showModal = true;
    }, 2000);
  },
  methods: {
    modalAction() {
      this.showModal = false;

      setTimeout(() => {
        location = "https://lp.ned.ie/stpatriksday";
      }, 200);
    },
  },
};
</script>

<style></style>
