<template>
  <div class="modal-wrapper" @click="close">
    <div class="modal" @click.stop>
      <div
        class="modal-header"
        :class="{ 'with-image': headImage || headImageMobile }"
        v-if="headImage || headTitle"
        @click="$emit('headerClick')"
      >
        <img :src="headImage" class="d-none d-sm-block" v-if="headImage" />
        <img :src="headImageMobile" class="d-sm-none" v-if="headImageMobile" />
        <h2 v-if="headTitle">{{ headTitle }}</h2>
      </div>
      <!-- <button class="btn btn-outline-white btn-circle" @click="close">
        <i class="fa fa-close"></i>
      </button> -->

      <div class="modal-body">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["headImage", "headImageMobile", "headTitle"],
  mounted() {
    document.body.style = "overflow: hidden;";
  },
  beforeUnmount() {
    document.body.style = "";
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style></style>
